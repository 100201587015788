import router from "next/router";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAlert, positions } from "react-alert";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { requestOTPAttributes } from "../../helpers/MoengageAttributeCreators";
import moengageEvent from "../../helpers/MoengageEventTracking";
import {
  changeNumber,
  loginWithOtp,
  sendOtp,
} from "../../store/actions/authActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrimaryButton from "../buttons/PrimaryButton";
function OtpVerificationForm({
  stageHandler,
  phoneNumber,
  childName,
  isAffiliates = false,
}) {
  const dispatch = useDispatch();
  const alert = useAlert();
  // ! redux states
  const authOtpRequestBody = useSelector(
    (state) => state.auth.authOtpRequestBody
  );
  const authDestinationPage = useSelector(
    (state) => state.auth.authDestinationPage
  );
  const authSourcePage = useSelector((state) => state.auth.authSourcePage);
  const gameTempChildAge = useSelector((state) => state.games.gameTempChildAge);
  const utmObject = useSelector((state) => state.utility.utmObject);
  // ! local state
  const [otp, setOtp] = useState("");
  const [alertPosition, setAlertPosition] = useState(positions.BOTTOM_CENTER);
  const [showResend, setShowResend] = useState(false);
  const [verificationRequested, setVerificationRequested] = useState(false);
  // ! Local helpers
  const verifyOtp = () => {
    if (otp.length !== 4) {
      alert.removeAll();
      alert.show("OTP should contain 4 numbers", {
        type: "error",
        position: alertPosition,
      });
      return;
    }
    setVerificationRequested(true);
    let userUpdateObject = null;
    if (utmObject?.utm_source) {
      userUpdateObject = {
        facebook_lead: {
          user_agent: window?.navigator?.userAgent || "",
          fb_click_id: window?.localStorage?.fbc || "",
          google_click_id: window?.localStorage?.gc || "",
          utm_campaign: utmObject?.utm_campaign || "",
          utm_content: utmObject?.utm_content || "",
          utm_medium: utmObject?.utm_medium || "",
          utm_source: utmObject?.utm_source || "",
          source: window.location.pathname,
        },
      };
    }
    if (authSourcePage === "/play/game") {
      if (childName) {
        if (!userUpdateObject)
          userUpdateObject = {
            student_profiles: [
              { age: +gameTempChildAge, full_name: childName },
            ],
          };
        else
          userUpdateObject.student_profiles = [
            { age: +gameTempChildAge, full_name: childName },
          ];
      }
    }
    let phoneNumberSplit = phoneNumber.split("-");
    dispatch(
      loginWithOtp(
        authOtpRequestBody,
        otp,
        phoneNumberSplit[0],
        phoneNumberSplit[1],
        false,
        () => {},
        () => {
          return authDestinationPage
            ? router.push(authDestinationPage)
            : router.push("/live");
        },
        true,
        userUpdateObject,
        window.location?.pathname?.includes("play") ? true : false,
        null,
        null,
        null,
        isAffiliates
      )
    );
  };
  // ! Resend OTP
  const resendOtp = () => {
    let phoneNumberSplit = phoneNumber.split("-");
    dispatch(sendOtp(authOtpRequestBody));
    alert.removeAll();
    alert.show("OTP sent!", {
      type: "success",
      position: alertPosition,
    });
    moengageEvent(
      "Request_Otp",
      requestOTPAttributes(1, phoneNumberSplit[0] == "+91" ? 1 : 2)
    );
  };
  // ! effects
  useEffect(() => {
    if (window.innerWidth > 992) setAlertPosition(positions.TOP_CENTER);
    setTimeout(() => {
      setShowResend(true);
    }, 5000);
  }, []);
  useEffect(() => {
    if (verificationRequested)
      setTimeout(() => {
        setVerificationRequested(false);
      }, 6000);
  }, [verificationRequested]);
  return (
    <div className="spark-auth-form__wrapper spark-otp-form__wrapper">
      <div className="spark-auth-form spark-otp-form">
        <h2 className="spark-auth-form__header">
          <span className="spark-auth-form__header--primary">
            OTP Verification
          </span>
          <span
            className="spark-auth-form__header--secondary"
            onClick={() => {
              stageHandler(1);
              dispatch(changeNumber());
            }}
          >
            Go back
          </span>
        </h2>
        <p className="spark-auth-form__sub-header">
          Enter OTP to verify your profile
        </p>
        <div className="spark-otp-form__otp-input-container">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            className="otp-input-boxes"
            onKeyDown={(ev) => {}}
            shouldAutoFocus={true}
            isInputNum={true}
            containerStyle={{
              height: "30px",
              width: "90%",
              margin: "20px auto",
              padding: "30px 10px",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#FFFFFF",
              boxShadow: "0px 0px 8px rgba(75, 0, 188, 0.08)",
              WebkitBoxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)",
              WebkitAppearance: "none",
              borderRadius: "8px",
            }}
          />
        </div>
        {showResend && (
          <div className="spark-otp-form__resend-otp-container">
            {verificationRequested ? (
              <CircularProgress size={30} />
            ) : (
              <span onClick={resendOtp}>Resend OTP</span>
            )}
          </div>
        )}
        <PrimaryButton
          buttonText="Verify"
          version="version-1"
          clickHandle={verifyOtp}
        />
      </div>
    </div>
  );
}

export default OtpVerificationForm;
