import React, { useState, useEffect } from "react";

function InputBox({
  placeholder,
  boxTitle,
  errorText,
  type,
  value,
  changeHandler,
  blurHandler,
  focusHandler,
  name,
  customRef,
  autoComplete,
  additionalClassname,
  ...props
}) {
  const [inputBoxClass, setInputBoxClass] = useState("input-box");
  useEffect(() => {
    if (errorText) setInputBoxClass("input-box error");
    if (!errorText && value?.length > 0)
      setInputBoxClass("input-box active filled");
  }, [errorText]);
  useEffect(() => {
    if (`${value}`?.length > 0) setInputBoxClass("input-box active filled");
  }, [value]);
  // console.log("this val", value);
  return (
    <div className="spark-booking-input-box__wrapper">
      <div
        className={
          additionalClassname
            ? `spark-booking-input-box ${additionalClassname}`
            : "spark-booking-input-box"
        }
      >
        {inputBoxClass === "input-box active" ||
        inputBoxClass === "input-box active filled" ? (
          <span className="spark-booking-input-box__title">{boxTitle}</span>
        ) : null}

        <input
          onChange={(ev) => changeHandler(ev.target.value)}
          name={name}
          value={value}
          {...props}
          ref={customRef}
          autoComplete={autoComplete}
          type={type}
          className={inputBoxClass}
          placeholder={placeholder}
          onFocus={() => {
            setInputBoxClass("input-box active");
            return focusHandler ? focusHandler() : null;
          }}
          onBlur={() => {
            errorText
              ? setInputBoxClass("input-box error")
              : value?.length > 0
              ? setInputBoxClass("input-box active filled")
              : setInputBoxClass("input-box");
            return blurHandler ? blurHandler() : null;
          }}
        />
        {inputBoxClass === "input-box error" ? (
          <span className="spark-booking-input-box__error-text">
            {errorText}
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default InputBox;
